import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Table, Typography, ConfigProvider } from 'antd';
import axios from 'axios';

const { Title } = Typography;

const scriptNameMapping = {
    HCD: 'Hathway',
    AI01: 'Trident',
    USF: 'Ujjivan',
    IC8: 'Idea',
};

const initialStockData = [
    { key: '1', Script: 'HCD', qty: 58580, avgCost: 23.56, ltp: 0, curVal: 0, pnl: 0, netChg: 0 },
    { key: '2', Script: 'AI01', qty: 5300, avgCost: 39.76, ltp: 0, curVal: 0, pnl: 0, netChg: 0 },
    { key: '3', Script: 'USF', qty: 4530, avgCost: 44.84, ltp: 0, curVal: 0, pnl: 0, netChg: 0 },
    { key: '4', Script: 'IC8', qty: 61900, avgCost: 15.01, ltp: 0, curVal: 0, pnl: 0, netChg: 0 },
];

const formatNumber = (num) => {
    return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(num);
};

const columns = [
    {
        title: 'Script',
        dataIndex: 'Script',
        key: 'Script',
        render: (text) => scriptNameMapping[text] || text,
    },
    { title: 'Qty.', dataIndex: 'qty', key: 'qty' },
    { title: 'Avg. Cost', dataIndex: 'avgCost', key: 'avgCost' },
    { title: 'LTP', dataIndex: 'ltp', key: 'ltp' },
    {
        title: 'Cur. Val.',
        dataIndex: 'curVal',
        key: 'curVal',
        render: (text) => <span>{formatNumber(parseFloat(text))}</span>,
    },
    {
        title: 'P&L',
        dataIndex: 'pnl',
        key: 'pnl',
        render: (text) => <span style={{ color: text < 0 ? 'red' : '#28cb28', fontWeight: 'bold' }}>{formatNumber(parseFloat(text))}</span>,
    },
    {
        title: 'Net Chg.',
        dataIndex: 'netChg',
        key: 'netChg',
        render: (text) => <span style={{ color: text < 0 ? 'red' : '#28cb28', fontWeight: 'bold' }}>{parseFloat(text).toFixed(2)}%</span>,
    },
];

const darkTheme = {
    token: {
        colorBgContainer: '#1f1f1f',
        colorText: '#ffffff',
        colorBorder: '#444444',
        colorBgCard: '#1f1f2f',
        colorTextHeading: '#ffffff',
    },
};

const cardStyle = {
    borderRadius: 8,
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    backgroundColor: darkTheme.token.colorBgCard,
    color: darkTheme.token.colorText,
};

const fetchStockData = async (script) => {
    try {
        const response = await axios.get(`https://priceapi.moneycontrol.com/pricefeed/nse/equitycash/${script}`);
        console.log(`Response for ${script}:`, response.data);
        return parseFloat(response.data.data.OFFERP);
    } catch (error) {
        console.error(`Error fetching data for ${script}:`, error);
        return null; // Return null in case of error
    }
};

// Check if the current time is within Indian stock market hours (9 AM to 3:30 PM IST)
const isMarketOpen = () => {
    const now = new Date();
    const indianOffset = now.getTimezoneOffset() + 330; // IST is UTC+5:30
    const marketOpenTime = new Date(now.setUTCHours(3, 30, 0, 0)); // 9:00 AM IST
    const marketCloseTime = new Date(now.setUTCHours(10, 0, 0, 0)); // 3:30 PM IST

    return now >= marketOpenTime && now <= marketCloseTime;
};

const Dashboard = () => {
    const [stockData, setStockData] = useState(initialStockData);

    useEffect(() => {
        const fetchData = async () => {
            if (isMarketOpen()) {
                const updatedStockData = await Promise.all(stockData.map(async (stock) => {
                    const ltp = await fetchStockData(stock.Script);

                    // If the market is closed or there was an error, keep the previous ltp
                    const newLtp = ltp !== null ? ltp : parseFloat(stock.ltp);

                    const curVal = (stock.qty * newLtp).toFixed(2);
                    const pnl = (curVal - (stock.qty * stock.avgCost)).toFixed(2);
                    const netChg = ((newLtp - stock.avgCost) / stock.avgCost * 100).toFixed(2);

                    return {
                        ...stock,
                        ltp: newLtp.toFixed(2),
                        curVal,
                        pnl,
                        netChg,
                    };
                }));

                setStockData(updatedStockData);
            }
        };

        fetchData();
        const intervalId = setInterval(fetchData, 10000); // Refresh data every 10 seconds

        return () => clearInterval(intervalId); // Cleanup on component unmount
    }, [stockData]);

    const totalInvestment = stockData.reduce((acc, item) => acc + (item.qty * item.avgCost), 0).toFixed(2);
    const totalCurrentValue = stockData.reduce((acc, item) => acc + parseFloat(item.curVal), 0).toFixed(2);
    const profitLoss = (totalCurrentValue - totalInvestment).toFixed(2);
    const totalNetChg = ((profitLoss / totalInvestment) * 100).toFixed(2);

    return (
        <ConfigProvider theme={darkTheme}>
            <Title level={2} style={{ color: '#1f1f2f' }}>Dashboard</Title>
            <div style={{ padding: 20 }}>
                <Row gutter={16}>
                    <Col span={8}>
                        <Card title={<Title level={4}>Total Investment Amount</Title>} bordered={false} style={cardStyle}>
                            <Title level={2}>{formatNumber(totalInvestment)}</Title>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card title={<Title level={4}>Current Portfolio Value</Title>} bordered={false} style={cardStyle}>
                            <Title level={2}>{formatNumber(totalCurrentValue)}</Title>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card title={<Title level={4}>Profit & Loss</Title>} bordered={false} style={cardStyle}>
                            <Title level={2} style={{ color: profitLoss < 0 ? 'red' : '#28cb28' }}>
                                {formatNumber(profitLoss)}
                            </Title>
                        </Card>
                    </Col>
                </Row>
                <Row gutter={16} style={{ marginTop: 20, textTransform: 'uppercase' }}>
                    <Col span={24}>
                        <Card title={<Title level={4}>Stock Market Portfolio</Title>} bordered={false} style={cardStyle}>
                            <Table
                                columns={columns}
                                dataSource={stockData}
                                pagination={false}
                                summary={() => (
                                    <Table.Summary fixed>
                                        <Table.Summary.Row style={{ backgroundColor: "#1f1f2f" }}>
                                            <Table.Summary.Cell colSpan={4}><strong>Total</strong></Table.Summary.Cell>
                                            <Table.Summary.Cell><strong style={{ color: darkTheme.token.colorText }}>{formatNumber(totalCurrentValue)}</strong></Table.Summary.Cell>
                                            <Table.Summary.Cell><span style={{ color: profitLoss < 0 ? 'red' : '#28cb28', fontWeight: 'bold' }}>{formatNumber(profitLoss)}</span></Table.Summary.Cell>
                                            <Table.Summary.Cell><strong style={{ color: darkTheme.token.colorText }}>{totalNetChg}%</strong></Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    </Table.Summary>
                                )}
                                rowClassName="custom-row"
                                className="transparent-table"
                            />
                        </Card>
                    </Col>
                </Row>
            </div>
        </ConfigProvider>
    );
};

export default Dashboard;
