import React from 'react';
import { Layout, Avatar, Switch, Badge, Button } from 'antd';
import { UserOutlined, BellOutlined, MenuOutlined, SunOutlined, MoonOutlined } from '@ant-design/icons';

const { Header } = Layout;

const Navbar = ({ toggleTheme, theme, onToggleSidebar, collapsed }) => (
    <Header style={{ background: '#1f1f2f', padding: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingRight: 20, width: collapsed ? 'calc(100% - 80px)' : 'calc(100% - 230px)', position: 'fixed', right: 0, zIndex: 1000 }}>
        <Button
            type="text"
            icon={<MenuOutlined style={{ color: '#FFF', fontSize: '24px', margin: '0 15px' }} />}
            onClick={onToggleSidebar}
            style={{ marginRight: 20, display: 'flex', justifyContent: 'flex-start' }}
        />

        <div style={{ display: 'flex', alignItems: 'center' }}>

            {/* <Switch
                checked={theme === 'dark'}
                onChange={toggleTheme}
                checkedChildren={<SunOutlined />}
                unCheckedChildren={<MoonOutlined />}
                style={{ color: '#FFF', fontSize: 24, marginRight: 20 }}
            /> */}
            <Badge count={5}><BellOutlined style={{ color: '#FFF', fontSize: 24, marginRight: 20 }} /></Badge>
            <Avatar size="large" icon={<UserOutlined />} />
        </div>
    </Header>
);

export default Navbar;
