import React from 'react';
import { Layout, Menu } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTachometerAlt, faChartBar, faCog, faCalculator, faUser } from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation } from 'react-router-dom'; // Named import
import smallLogo from '../assets/images/Small-Logo.svg';
import largeLogo from '../assets/images/Logo.svg';

const { Sider } = Layout;

const Sidebar = ({ collapsed, onCollapse }) => {
    const location = useLocation();

    return (
        <Sider collapsible collapsed={collapsed} onCollapse={onCollapse} style={{ background: '#1f1f2f', height: '100vh', position: 'fixed', left: 0, zIndex: 1000 }} width={230}>
            <div className="logo" style={{ height: 32, margin: 16, textAlign: 'center' }}>
                <img src={collapsed ? smallLogo : largeLogo} alt="logo" style={{ width: '100%', height: 'auto' }} />
            </div>
            <Menu
                theme="dark"
                mode="inline"
                selectedKeys={[location.pathname]}
                style={{ background: '#1f1f2f', marginTop: 60 }}
                className="custom-sidebar-menu"
            >
                <Menu.Item key="/dashboard" icon={<FontAwesomeIcon icon={faTachometerAlt} />}><Link to="/dashboard">Dashboard</Link></Menu.Item>
                <Menu.Item key="/pre-ipo" icon={<FontAwesomeIcon icon={faChartBar} />}><Link to="/pre-ipo">Pre-IPO</Link></Menu.Item>
                <Menu.Item key="/research" icon={<FontAwesomeIcon icon={faCog} />}><Link to="/research">Research List</Link></Menu.Item>
                <Menu.Item key="/calculator" icon={<FontAwesomeIcon icon={faCalculator} />}><Link to="/calculator">Investment Calculator</Link></Menu.Item>
                <Menu.Item key="/profile" icon={<FontAwesomeIcon icon={faUser} />}><Link to="/profile">Profile</Link></Menu.Item>
            </Menu>
        </Sider>
    );
};

export default Sidebar;
