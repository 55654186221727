// src/App.js
import React, { useState } from 'react';
import { Layout } from 'antd';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';  // Default export
import Sidebar from './components/Sidebar'; // Default export
import Dashboard from './pages/Dashboard'; // Default export
import Gifts from './pages/Gifts'; // Default export
import PreIPO from './pages/PreIPO'; // Default export
import Research from './pages/Research'; // Default export
import Calculator from './pages/Calculator'; // Default export
import Cart from './pages/Cart'; // Default export
import Profile from './pages/Profile'; // Default export

import './styles/App.css';

const { Content } = Layout;

const App = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [theme, setTheme] = useState('light');

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Router>
      <Layout style={{ minHeight: '100vh' }}>
        <Sidebar collapsed={collapsed} onCollapse={toggleSidebar} />
        <Layout style={{ marginLeft: collapsed ? 80 : 230 }}>
          <Navbar toggleTheme={toggleTheme} theme={theme} onToggleSidebar={toggleSidebar} collapsed={collapsed} />
          <Content style={{ marginTop: 64, padding: 24 }}>
            <Routes>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/gifts" element={<Gifts />} />
              <Route path="/pre-ipo" element={<PreIPO />} />
              <Route path="/research" element={<Research />} />
              <Route path="/calculator" element={<Calculator />} />
              <Route path="/cart" element={<Cart />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/" element={<Dashboard />} />
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </Router>
  );
};

export default App;
